@import "bootstrap";

img { margin-bottom: 4px;
     margin-top: 8px;
}

.navbar-default {
  background-color: #ffffff !important;
  border-color: #ffffff !important;
  border-bottom-width: thin;
  margin-top: 0px;
}
.navbar-brand {
  font-style:normal;
  font-family: soleil,Helvetica,Arial,sans-serif;
  font-size:16pt;
  font-weight:bold;
  color: #555555 !important;
}

.well {
  background-color: #D7CEC7 !important;
  border-color: #ffffff !important;
}

.panel-footer {
  background-color: #ffffff !important;
  border-width: 0px;
  border-color: #ffffff !important;
}

body {
	padding-top: 55px;
  border-color: #ffffff !important;
  font-size: 15px;
}

figcaption {
    text-align: right;
    margin-top: -20px;
    margin-bottom: 20px;
}

.panel{
    margin-bottom: 0px;
}


/* Top navigation bar */


p.byline {
	text-align: center;
	font-size: 14px;
	line-height: 36px;
	margin-top: -24px;
	margin-bottom: 48px;
}


pubtit {
	font-weight: bold;
}

firstp {
}

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  max-width: 1000px;  /* or 950px */
  border-color: #ffffff !important
}

//.row {
//    padding-top: 20px;
//}

/* Home, News */
#newsid p {
	text-align: left;
}

#homeidtmp h1 {
	text-align: center;
}

#newsid img {
	margin-top: 6px;
	margin-bottom: 6px;
}

/* Team & Publications */
#gridid img { margin: 8px 22px 6px 0; border-radius:0%; box-shadow: 2px 2px 5px #888888 }
#homeid img { margin: 0 0 0 0; border-radius:0%; box-shadow: 0px 0px 0px #888888 }
#picid img { margin: 6px 3px 60px 6px; border-radius:0%; box-shadow: 2px 2px 5px #888888 }
#gridid .row { margin-bottom: 24px; }


/* Grid overrides */
.col-sm-1, .col-sm-2, .col-sm-3, .col-sm-5, .col-sm-6,
.col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
	padding-left: 16px;
	padding-right: 16px;
    padding-bottom: 15px;
}

.col-sm-8, .col-sm-11 {
    text-align: center;
    width: inherit;
    padding-top: 15px;
}

/* Grid overrides */
.col-sm-4 {
	padding-left: 26px;
	padding-right: 26px;
}


/*gallery*/

.img-responsive {
    //width: 1000px;
    border-radius: 0px;
}


/* Carusel */

.carousel {
    width:90%;
    align-content: center;
    margin-bottom: 20px;
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 30px;
    border-radius: 0px;
}
